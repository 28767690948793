<template>
  <!-- 意见反馈 -->
  <div>
    <!-- 面包屑导航区域 这个组件的样式其他组件也用的到 所以写到公共样式里 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> <!-- 点击回到welcome 因为重定向 -->
      <el-breadcrumb-item>服务管理</el-breadcrumb-item>
      <el-breadcrumb-item>意见反馈</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <!-- layout栅格 设置多少行多少列 span是宽度 gutter是间隙 -->
      <el-row :gutter="20">

        <el-col :span="8">
          <!-- 搜索与添加区域 -->
          <el-input placeholder="请输入内容" v-model="queryInfo.name" clearable>
            <!-- 将value和query双向绑定 点击搜索就发送请求 请求相应的数据 放到userlist里 如果默认不搜索就是请求所有的数据 同时加上clearble属性 使得点击可以清除输入内容 于是触发clear事件 监听事件让下面显示所有数据 -->
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col>
      </el-row>

      <!-- 用户列表区域 -->
      <el-table :data="userlist" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="反馈内容" prop="userRemark"></el-table-column>
        <el-table-column label="添加图片" prop="coverUrl">
          <template slot-scope="scope">
            <img style="height: 30px;" v-for="(item, index) in arrStrToArr(scope.row.imgUrl)" :key="index" :src="item" />
          </template>
        </el-table-column>
        <el-table-column label="是否采纳">
          <template slot-scope="scope"> <!-- 作用域插槽 新版是v-slot="scope" scope.row代表这一行的所有数据 这是从上面userlist里拿出来的 -->
            <el-switch v-model="scope.row.accept" :active-value="1" :inactive-value="0"
              @change="amendAccept(scope.row)"> <!-- 将开关的状态和数据里的mg_state绑定 -->
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否联系">
          <template slot-scope="scope"> <!-- 作用域插槽 新版是v-slot="scope" scope.row代表这一行的所有数据 这是从上面userlist里拿出来的 -->
            <el-switch v-model="scope.row.relation" :active-value="1" :inactive-value="0"
              @change="amendRelation(scope.row)"> <!-- 将开关的状态和数据里的mg_state绑定 -->
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="联系方式" prop="state"></el-table-column>
        <el-table-column label="提交时间" prop="updateTime"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope"> <!-- 作用域插槽 -->
            <!-- 回复按钮 -->
            <el-button type="danger" icon="el-icon-chat-square" size="mini" @click="showReply(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--反馈内容回复 -->
      <el-dialog title="反馈内容回复" :visible.sync="addDialogVisible" width="40%">
        <el-form :model="addForm" ref="addFormRef" label-width="100px">
          <el-form-item label="回复内容">
            <el-input v-model="reply" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="determineForward">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total"> <!-- layout表示显示哪些东西 -->
      </el-pagination>

    </el-card>
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象 原数据是一整条 根据页数和条数返回相应的数据 如每页显示3条 第2页 就会把第4 5 6个数据返回 拿到后就把这3个渲染出来 total控制着页码组件的数据显示
      queryInfo: {
        name: "",
        state: "",
        // 当前的页数
        page: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      userlist: [], /* 返回的数据存储到这里 */
      total: 0,
      addDialogVisible: false,
      reply: '',
      addForm: ''
    }
  },
  created() {
    this.getUserList()
  },
  methods: {
    //确认发送
    async determineForward() {
      const { data: res } = await this.$http.get('/Feedback/FeedbackReply', {
        params: {
          id: this.id,
          reply: this.reply
        }
      })
      if (res.code != 1) {
        return this.$message.error('发送消息失败')
      }
      this.$message.success(res.data);
      addDialogVisible = false;
      this.getUserList();

    },
    arrStrToArr(str) {
      return str.split(',');
    },
    // --------请求方法-----
    async getUserList() {
      console.log(this.queryInfo);
      const { data: res } = await this.$http.get('/Feedback/getFeedbackList', {
        params: this.queryInfo
      })
      if (res.code != 1) {
        return this.$message.error('获取列表失败！')
      }
      this.userlist = res.data.records;
      console.log(res.data);
      this.total = res.data.total;
      console.log(this.userlist)
    },
    // 监听 下拉页码 改变的事件 newsize为选择的条数 选择了几条就把这个作为参数传给数据请求中重新请求
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 改变的事件 newPage为选择的页码值 选择了第几页就把这个页码作为参数传给数据请求中重新请求
    handleCurrentChange(newPage) {
      console.log(newPage)
      this.queryInfo.page = newPage
      this.getUserList()
    },
    // 监听是否采纳
    async amendAccept(item) {
      console.log(item);
      console.log(item.accept);
      console.log(item.accept == 1);
      const { data: res } = await this.$http.get('/Feedback/amendAccept', {
        params: {
          id: item.id,
          accept: item.accept == 0 ? 0 : 1
        }
      })
      if (res.code != 1) { /* 原数据修改失败 页面的状态重置回去（保持跟原数据一样 刷新也行） */
        return this.$message.error('更新用户状态失败！')
      }
      this.$message.success('更新用户状态成功！');
      this.getUserList()
    },
     // 监听是否联系
     async amendRelation(item) {
      console.log(2222,item);
      const { data: res } = await this.$http.get('/Feedback/amendRelation', {
        params: {
          id: item.id,
          relation: item.relation == 0 ? 0 : 1
        }
      })
      if (res.code != 1) { /* 原数据修改失败 页面的状态重置回去（保持跟原数据一样 刷新也行） */
        return this.$message.error('更新用户状态失败！')
      }
      this.$message.success('更新用户状态成功！');
      this.getUserList()
    },
    //分页
    // 监听 下拉页码 改变的事件 newsize为选择的条数 选择了几条就把这个作为参数传给数据请求中重新请求
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },

    // 根据Id回复对应的信息
    showReply(id) {
      this.id = id
      this.addDialogVisible = true;
    },

  },

}
</script>
<style lang="less" scoped></style>